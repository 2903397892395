import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import bookCategoriesSAService from "services/superadmin/bookCategoriesSAService";

const BookCategoryForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { category },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Название категории(константа)",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      value: "active",
      options: [
        { id: "active", name: "Активный" },
        { id: "inactive", name: "Неактивный" },
      ],
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (category) {
        await bookCategoriesSAService.put(id, formValues)
        snack("Категория обновлена успешно!");
      } else {
        await bookCategoriesSAService.post(formValues)
        snack("Категория создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={category ? 'Редактирование категории' : 'Создание категории'}>
      <AppForm onSubmit={onSubmit} data={category} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(BookCategoryForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['category'] = () => bookCategoriesSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
