import { Outlet } from "react-router-dom";
import { AllahNameList } from "./AllahNameList";
import AllahNameForm from "./AllahNameForm";

export const AllahNameRoutes = [
  {
    path: "allah-names",
    element: Outlet,
    children: [
      {
        path: "",
        element: AllahNameList,
      },
      {
        path: "add",
        element: AllahNameForm,
      },
      {
        path: "edit/:id",
        element: AllahNameForm,
      },
    ],
  },
];
