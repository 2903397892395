import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import countriesSAService from "services/superadmin/countriesSAService";

const CountryForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { country },
}) => {
  const fieldsWithData = [
    {
      key: "name",
      label: "Наименование страны",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "countryCode",
      label: "Код страны (2-х значный)",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      defaultValue: 'active',
      options: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'inactive',
          name: 'Неактивный'
        },
      ]
    },
    {
      key: "prayMethod",
      label: "Метод намаза",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'MWL',
          name: 'Muslim World League'
        },
        {
          id: 'ISNA',
          name: 'Islamic Society of North America (ISNA)'
        },
        {
          id: 'Egypt',
          name: 'Egyptian General Authority of Survey'
        },
        {
          id: 'Makkah',
          name: 'Umm Al-Qura University, Makkah'
        },
        {
          id: 'Karachi',
          name: 'University of Islamic Sciences, Karachi'
        },
        {
          id: 'Tehran',
          name: 'Institute of Geophysics, University of Tehran'
        },
        {
          id: 'Jafari',
          name: 'Shia Ithna-Ashari, Leva Institute, Qum'
        },
      ]
    }
  ]

  const onSubmit = async (formValues) => {
    try {
      if (country) {
        await countriesSAService.put(id, formValues)
        snack("Страна обновлена успешно!");
      } else {
        await countriesSAService.post(formValues)
        snack("Страна создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={country ? 'Редактирование страны' : 'Создание страны'}>
      <AppForm onSubmit={onSubmit} data={country} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(CountryForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['country'] = () => countriesSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
