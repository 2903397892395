import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useGetBookCategories } from 'hooks/useGetBookCategories';
import bookCategoriesSAService from 'services/superadmin/bookCategoriesSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'name',
        label: 'Название'
    },
    {
        key: "status",
        label: "Статус"
    },
    TableColumns.createdDate,
]

export const BookCategoryList = () => {
    const { data, loading, refetch } = useGetBookCategories();

    const onDelete = async (u) => {
        await bookCategoriesSAService.delete(u.id);
        refetch();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список категорий">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
