import { Outlet } from "react-router-dom";
import { EventList } from "./EventList";
import EventForm from "./EventForm";

export const eventsRoutes = [
  {
    path: "events",
    element: Outlet,
    children: [
      {
        path: "",
        element: EventList,
      },
      {
        path: "add",
        element: EventForm,
      },
      {
        path: "edit/:id",
        element: EventForm,
      },
    ],
  },
];
