import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import React, { useState } from 'react';
import tourPackagesService from 'services/admin/tour-packages.service';
import './TourPackageMedia.scss';

const TourPackageMedia = ({ router: { params: { id } }, data: { tourPackage } }) => {
    // Выбранные изображения
    const [images, setImages] = useState(tourPackage.media ? [...tourPackage.media] : []);

    // Загрузка изображений
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setImages([...images, ...files]);
        }
    }

    const handleSave = async () => {
        const formData = new FormData();
        images.forEach(image => {
            formData.append('images', image);
        });

        try {
            await tourPackagesService.uploadImages(id, formData);
            alert('Изображения успешно загружены');
        } catch (error) {
            console.error(error);
            alert('Ошибка при загрузке изображений');
        }
    }

    const handleImageRemove = (index) => {
        setImages(images.filter((_, i) => i !== index));
    }

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    }

    const handleDragOver = (e, index) => {
        e.preventDefault();
    }

    const handleDrop = (e, index) => {
        const draggedIndex = e.dataTransfer.getData('index');
        const newImages = [...images];
        newImages.splice(index, 0, newImages.splice(draggedIndex, 1)[0]);
        setImages(newImages);
    }

    return <div>
        <div className='m-t-32'>
            <div className='m-b-16'>
                <Link to={`/admin/tour-packages/view/${id}`} style={{ textDecoration: 'none', color: 'inherit', marginBottom: '16px' }}> Назад</Link>
            </div>
            <h3>{tourPackage.name}</h3>
        </div>
        <div className='m-t-32'>
            <h4 className='m-b-16'>Рисунки отеля</h4>
            <section id="images-preview">
                <div className='image-preview'>
                    {images.map((image, index) => {
                        if (image instanceof File) {
                            return <div key={index} className='image-preview-item relative'>
                                <img src={URL.createObjectURL(image)} alt={image.name} />
                                <button className='remove-image-button absolute top-0 right-0' onClick={() => handleImageRemove(index)}>x</button>
                            </div>
                        } else {
                            // TODO: Возможность изменить порядок изображений при помощи drag and drop
                            return <div key={index} className='image-preview-item relative' draggable={true}
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={(e) => handleDragOver(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                style={{ cursor: 'move' }}
                            >
                                <img src={image} alt={image} />
                                <button className='remove-image-button absolute top-0 right-0' onClick={() => handleImageRemove(index)}>x</button>
                            </div>
                        }
                    })}
                </div>
            </section>
            <input type="file" multiple onChange={handleImageUpload} accept="image/*" className='m-t-16' />
        </div>
        <div className='m-t-32'>
            <Button variant="contained" color="primary" onClick={handleSave}>Сохранить</Button>
        </div>
    </div>;
};

export default withRouter(
    withRemoteDataAndSpinner(TourPackageMedia, (router) => {
        let requests = {
        }
        if (router.params.id) {
            requests['tourPackage'] = () => tourPackagesService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);