import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import AllahNamesSAService from 'services/superadmin/AllahNamesSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'arabicName',
        label: 'Название на арабском'
    },
    {
        key: "transcript",
        label: "Транскрип",
    },
    {
        key: "translation",
        label: "Перевод",
    },
    {
        key: "language",
        label: "Язык",
    },
    TableColumns.createdDate,
]

export const AllahNameList = () => {
    const [data, loadData, loading] = useRemoteResource(AllahNamesSAService.getAll, "");

    const onDelete = async (u) => {
        await AllahNamesSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список имен Аллаха">
            <AppAdminTable
                columnConfig={listConfig}
                data={data || []}
                onDelete={onDelete}
                loading={loading}
            />
        </ListPage>
    );
}
