import { useSelector } from "react-redux";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import "./admin.scss";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { withUser } from "hoc/withUser";
import UserMenu from "components/UserMenu/UserMenu";
import { getAuthUser } from "redux/selectors/getUser";
import Logo from "components/Logo/Logo";
import {
  MdSupervisorAccount,
  MdOutlineGroups,
  MdOutlineModeComment,
  MdLogout,
  MdAirplaneTicket,
  MdSettings,
  MdOutlineAirplaneTicket,
  MdLocalActivity,
  MdBookmarks,
  MdHotel,
  MdPayments,
  MdBarChart,
} from "react-icons/md";
import { useEffect, useRef } from "react";
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FaBars } from "react-icons/fa";
import CurrencyRates from "components/CurrencyRates";

const AdminLayout = () => {
  const authUser = useSelector(getAuthUser);
  const sidebar = useRef(null);
  const burger = useRef(null);
  const location = useLocation();

  const adminMenu = [
    {
      icon: MdBarChart,
      path: "stats",
      label: "Аналитика",
    },
    {
      icon: MdSupervisorAccount,
      path: "customers",
      label: 'Управление клиентами',
      children: [
        {
          icon: MdSupervisorAccount,
          path: "customers/all",
          label: "Клиенты",
        },
        {
          icon: MdSupervisorAccount,
          path: "customers/passport",
          label: "Просроченные паспорта",
        },
        {
          icon: MdSupervisorAccount,
          path: "customers/visas",
          label: "Просроченные визы",
        },
        {
          icon: MdSupervisorAccount,
          path: "customers/debtors",
          label: "Реестр должникиов",
        },
      ]
    },
    {
      icon: MdLocalActivity,
      path: "tour-packages",
      label: "Управление турпакеты",
      children: [
        {
          icon: MdLocalActivity,
          path: "tour-packages/list",
          label: "Турпакеты",
        },
        {
          icon: MdHotel,
          path: "tour-packages/hotels",
          label: "Отели",
        },
        {
          icon: MdOutlineAirplaneTicket,
          path: "tour-packages/airlines",
          label: "Авиакомпании",
        },
        {
          icon: MdAirplaneTicket,
          path: "tour-packages/flights",
          label: "Расписание рейсов",
        },
        {
          icon: MdBookmarks,
          path: "tour-packages/attributes",
          label: "Атрибуты турпакета",
        },
        {
          icon: MdBookmarks,
          path: "tour-packages/tour-schedules",
          label: "Расписание туров",
        },
        {
          icon: MdBookmarks,
          path: "tour-packages/tourpackage-categories",
          label: "Категории турпакетов",
        },
      ]
    },
    {
      icon: MdPayments,
      path: "bookings",
      label: "Управление заказами",
      children: [
        {
          icon: MdPayments,
          path: "bookings/list",
          label: "Заказы",
        },
        {
          icon: MdPayments,
          path: "bookings/payments",
          label: "История платежей",
        },
        {
          icon: MdPayments,
          path: "bookings/unpaid-clients",
          label: "Графа должников",
        },
      ]
    },
    {
      icon: MdAirplaneTicket,
      path: "group-trips",
      label: "Поездки"
    },
  ];

  const advancedAdminMenu = [
    {
      icon: MdSettings,
      path: "orgs/:id/edit".replace(':id', localStorage.getItem('orgID')),
      label: "Настройки",
    },
    {
      icon: MdSettings,
      path: 'faq',
      label: "Вопросы-Ответы",
    },
    {
      icon: MdSettings,
      path: 'locations',
      label: "Топ локаций",
    },
    {
      icon: MdOutlineGroups,
      path: "invoices",
      label: "Счет на оплату",
    },
    {
      icon: MdSupervisorAccount,
      path: "members",
      label: "Сотрудники",
    },
    {
      icon: MdSupervisorAccount,
      path: "whatsapp/connect",
      label: "Whatsapp Интергация",
    },
    {
      icon: MdOutlineModeComment,
      path: "comments",
      label: "Обратная связь",
    },
    {
      icon: MdLogout,
      path: "/auth/logout",
      label: "Выйти",
    },
  ];

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!sidebar.current) {
        return;
      }
      if (!sidebar.current.contains(event.target) && !burger.current.contains(event.target)) {
        sidebar.current.classList.remove("active");
      }
    });
  }, []);

  useEffect(() => {
    sidebar.current.classList.remove("active");
  }, [location]);

  return (
    <div className="page-wrapper" id="main-wrapper">
      <aside className="left-sidebar admin-nav" ref={sidebar}>
        <div className="admin-logo flex items-center">
          <Logo />
        </div>

        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <nav className="sidebar-nav">
            <List>
              {adminMenu.map((item, index) => {
                return <div key={index} className="menu-item">
                  <ListItemButton component={NavLink} to={item.path} className="sidebar-link">
                    <ListItemIcon sx={{ minWidth: '1.5rem' }}>
                      {item.icon && <item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.label}></ListItemText>
                  </ListItemButton>
                  <div className="sub-menu-wrapper" timeout="auto">
                    {item.children && item.children.map((c, subInx) => {
                      return <List component="div" disablePadding className="sub-menu" key={subInx}>
                        <ListItemButton sx={{ pl: 4 }} component={NavLink} to={c.path}>
                          <ListItemIcon sx={{ minWidth: '1.5rem' }}>
                            {item.icon && <item.icon />}
                          </ListItemIcon>
                          <ListItemText primary={c.label} />
                        </ListItemButton>
                      </List>
                    })}
                  </div>
                </div>
              })}
            </List>

            <Divider />
            <List>
              {advancedAdminMenu.map((item, index) => {
                return <div key={index}>
                  <ListItemButton component={NavLink} to={item.path} className="sidebar-link">
                    <ListItemIcon sx={{ minWidth: '1.5rem' }}>
                      {item.icon && <item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.label}></ListItemText>
                  </ListItemButton>
                  <Collapse in={true} timeout="auto">
                    {item.children && item.children.map((c, subInx) => {
                      return <List component="div" disablePadding className="sub-menu" key={subInx}>
                        <ListItemButton sx={{ pl: 4 }} component={NavLink} to={c.path}>
                          <ListItemIcon sx={{ minWidth: '1.5rem' }}>
                            {item.icon && <item.icon />}
                          </ListItemIcon>
                          <ListItemText primary={c.label} />
                        </ListItemButton>
                      </List>
                    })}
                  </Collapse>
                </div>
              })}
            </List>

            <Divider />
            <section className="text-center fs-14 p-16">
              <Link className="" to="/privacy-policy">Privacy Policy</Link>
              <div className="m-t-8">{new Date().getFullYear()}. All rights reserved.</div>
            </section>
          </nav>
        </Box>
      </aside>
      <section className="admin-content">
        <div className="ml-auto flex justify-between">
          <div className="flex-1 no-mobile">
            <CurrencyRates></CurrencyRates>
          </div>
          <div className="flex">
            <UserMenu
              user={authUser}
              setMobileOpen={() => {
                sidebar.current.classList.toggle("active");
              }}
            />
            <IconButton
              ref={burger}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                sidebar.current.classList.toggle("active");
              }}
              sx={{ display: { sm: "none" } }}
            >
              <FaBars></FaBars>
            </IconButton>
          </div>
        </div>
        <Breadcrumb />
        <Outlet />
      </section>
    </div >
  );
};

export default withUser(AdminLayout);
