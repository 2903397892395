export const toCurrencyFormat = (amount, currency) => {
    if (!amount) {
        amount = 0
    }

    if(typeof amount === 'string'){
        amount = Number(amount)
    }

    const format = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: currency,
    });
        
    return format.format(amount)
}

export const CURRENCY = {
    "USD": 'USD',
    'KZT': 'KZT',
    'RUB': 'RUB'
}