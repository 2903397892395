import inputTypeEnum from "enums/inputTypes";
import { TableColumns } from "../helpers/tableColumns";

export const listConfig = [
  TableColumns.name,
  {
    key: 'website',
    label: 'Сайт'
  },
  {
    key: 'address',
    label: 'Адрес компаний'
  },
];

export const listConfigSuperAdmin = [...listConfig, TableColumns.createdBy];

export const addConfig = [
  {
    key: 'logo',
    label: 'Логотип',
    inputType: inputTypeEnum.file
  },
  TableColumns.name,
  {
    key: 'short_description',
    label: 'Краткое описание',
    inputType: inputTypeEnum.richText
  },
  {
    key: 'description',
    label: 'Описание',
    inputType: inputTypeEnum.richText
  },
  {
    key: 'website',
    label: 'Сайт',
    inputType: inputTypeEnum.text
  },
  {
    key: 'address',
    label: 'Адрес компаний',
    inputType: inputTypeEnum.text
  },
];
export const editConfig = [
  TableColumns.name,
  {
    key: 'website',
    label: 'Сайт',
    inputType: inputTypeEnum.text
  },
  {
    key: 'address',
    label: 'Адрес компаний',
    inputType: inputTypeEnum.text
  },
];
