import { Outlet } from "react-router-dom";
import { AppVersionList } from "./AppVersionList";
import AppVersionForm from "./AppVersionForm";
import { AppVersionUpdatesList } from "../AppVersionUpdates/AppUpdatesList";
import AppVersionUpdateForm from "../AppVersionUpdates/AppUpdatesForm";

export const appVersionsRoutes = [
  {
    path: "app-versions",
    element: Outlet,
    children: [
      {
        path: "",
        element: AppVersionList,
      },
      {
        path: "add",
        element: AppVersionForm,
      },
      {
        path: "edit/:id",
        element: AppVersionForm,
      },
      {
        path: "view/:id/app-updates/add",
        element: AppVersionUpdateForm,
      },
      {
        path: "view/:id/app-updates/edit/:updateID",
        element: AppVersionUpdateForm,
      },
      {
        path: "view/:id/app-updates",
        element: AppVersionUpdatesList,
      },
    ],
  },
];
