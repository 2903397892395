import { Outlet } from "react-router-dom";
import { ReminderList } from "./ReminderList";
import ReminderForm from "./ReminderForm";

export const remindersRoutes = [
  {
    path: "reminders",
    element: Outlet,
    children: [
      {
        path: "",
        element: ReminderList,
      },
      {
        path: "add",
        element: ReminderForm,
      },
      {
        path: "edit/:id",
        element: ReminderForm,
      },
    ],
  },
];
