import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import AppVersionsSAService from "services/superadmin/AppVersionsSAService";

const AppVersionForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { appVersion },
}) => {

  const fieldsWithData = [
    {
      key: "versionIOS",
      label: "Версия для iOS",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "versionAndroid",
      label: "Версия для Android",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "isActive",
      label: "Активный",
      inputType: inputTypeEnum.checkbox
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (appVersion) {
        await AppVersionsSAService.put(id, formValues)
        snack("Версия обновлена успешно!");
      } else {
        await AppVersionsSAService.post(formValues)
        snack("Версия создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={appVersion ? 'Редактирование версии' : 'Создание версии'}>
      <AppForm onSubmit={onSubmit} data={appVersion} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(AppVersionForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['appVersion'] = () => AppVersionsSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
