import { CircularProgress } from "@mui/material";
import { useGetDeviceVersionNumbers } from "hooks/useGetDeviceVersionNumbers";
import React, { useEffect } from "react";

const DeviceVersionNumbers = ({ platform }) => {
    const { data, isLoading, error } = useGetDeviceVersionNumbers(platform)

    useEffect(() => {
        let ctx = null
        if (data?.length > 0) {
            ctx = document.getElementById(`deviceVersionNumbers-${platform}`);
            const labels = data.map(x => x.version)

            new window.Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: data.map(x => x.count),
                        backgroundColor: new Array(12).fill('rgba(75, 192, 192, 0.2)'),
                        borderColor: new Array(12).fill('rgb(75, 192, 192)'),
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                },
            });
        }
    }, [data, platform]);

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }
    if (error) {
        return <div>{error}</div>
    }

    return (
        <div className="flex-1">
            <h3 className="">Устройства по версиям {platform}</h3>
            <div style={{ maxWidth: '100%' }}>
                <canvas id={`deviceVersionNumbers-${platform}`} style={{ width: '100% !important' }}></canvas>
            </div>
        </div>
    );
};

export default DeviceVersionNumbers