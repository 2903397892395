import { Autocomplete, Button, Checkbox, FormControl, Icon, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { Box } from "@mui/system";
import TextEditor from "components/TextEditor/TextEditor";
import inputTypeEnum from "enums/inputTypes";
import { toNativeDate } from "helpers/dateHelper";
import "./form.scss";
import _ from 'lodash'
import { objectToPlain } from 'hajjtravel-utils'
import { Controller, useForm } from "react-hook-form";
import { snack } from "components/Snack/Snack";
import { FaFilePdf } from 'react-icons/fa'

const defaultValues = (fields, data) => {
    if (fields && data) {
        return Object.entries(data).reduce((acc, [k, v]) => {
            if (fields.find(x => x.key === k)) {
                return {
                    ...acc,
                    [k]: v
                }
            }
            return acc
        }, {})
    }
    return {}
}

const PreviewField = ({ file }) => {
    if (file.endsWith(".pdf")) {
        return (
            <div style={{ width: 100, height: 'auto', marginTop: "1rem" }}>
                <a href={file} target="_blank" rel="noopener noreferrer">
                    <FaFilePdf size={100} />
                </a>
            </div>
        )
    }

    return (
        <div style={{ width: 100, height: 'auto', marginTop: "1rem" }}>
            <img src={file} alt="" />
        </div>
    )
}

/**
 * Чтобы контролировать элементы формы используется библиотека react-hook-form
 * Все элементы контролируемые ReactJS.
 * 
 * Если используется value в input то это значит компонент является контролируемым
 * 
 * Вопросы:
 * Как задать девольное значение для каждого поля 
 */
const AppForm = ({ onSubmit, data, fields, formProps = {}, isLoading = false }) => {
    /**
     * Переводит все в плоский формат. То есть если бэкенд отправляет в вложенном формате то он переводит
    */
    data = objectToPlain(data, "", {})
    console.log("objectToPlain:", data);

    const { register, handleSubmit, setValue, control, getValues } = useForm(
        {
            defaultValues: data ? defaultValues(fields, data) : {}
        }
    );
    /**
     * Данная функция: 
     * - Валидирует поля, 
     * - Убирает пустые пробелы из строк, 
     * - Фильтрует поля с пустыми значениями   
     * - Устанавливает false для checkbox так как это не поддерживается из коробки
     * - Заново переводит в FormData и передает родителю
     * 
     * Есть сложность работы с другими компонентами такие как Mui Autocomplete
     */
    function handleSubmitAfter(hookValue, e) {
        onSubmit(hookValue, e)
    }

    return (
        <form onSubmit={handleSubmit(handleSubmitAfter)} {...formProps}>
            <Box sx={{ marginBottom: "20px" }}>
                {fields &&
                    fields.map((f, fieldIndex) => {
                        const commonProps = {
                            required: f.required === true,
                        };

                        if ([inputTypeEnum.text, inputTypeEnum.email, inputTypeEnum.number].includes(f.inputType)) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__input">
                                        <TextField
                                            {...register(f.key)}
                                            name={f.key}
                                            id={f.key}
                                            defaultValue={data ? _.get(data, f.key) : ""}
                                            className="w-100"
                                            autoComplete="false"
                                            type={f.inputType}
                                            required={f.required}
                                            label={f.label}
                                            disabled={!!f.disabled}
                                            readOnly={!!f.readOnly}
                                        ></TextField>
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.tel) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__input">
                                        <Controller
                                            control={control}
                                            name={f.key}
                                            render={({ field: { value } }) => {
                                                return <TextField
                                                    defaultValue={value && f.formatter ? f.formatter(value, getValues) : ''}
                                                    label={f.label}
                                                    name={f.key}
                                                    id={f.key}
                                                    className="w-100"
                                                    autoComplete="false"
                                                    required={f.required}
                                                    type={f.inputType}
                                                    onInput={function (e) {
                                                        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                                        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                                    }}
                                                ></TextField>
                                            }}
                                        ></Controller>
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.hidden) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__input">
                                        <input
                                            {...register(f.key)}
                                            type="hidden"
                                            name={f.key}
                                            id={f.key}
                                            defaultValue={_.get(data, f.key) || f?.defaultValue}
                                            autoComplete="false"
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.select) {
                            return (
                                <div className="m-b-16" key={f.key}>
                                    <FormControl fullWidth>
                                        <InputLabel>{f.label}</InputLabel>
                                        <Select
                                            {...register(f.key)}
                                            id={f.key}
                                            name={f.key}
                                            key={f.key}
                                            multiple={f.multiple}
                                            placeholder={f.label}
                                            label={f.label}
                                            required={!!f.required}
                                            defaultValue={f.options && data && data[f.key] ? f.options.find(o => o.id === data[f.key])?.id : f.value || ""}
                                            onChange={(e) => f.onChange ? f.onChange(e.target.value, fields) : () => { }}
                                        >
                                            {f?.options?.map((o, index) => {
                                                return (
                                                    <MenuItem key={index} value={o?.id}>
                                                        {o?.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.checkbox) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__label flex">
                                        <label className="w-100" htmlFor={f.key}>
                                            {typeof f.label === "string" ? f.label : f.label()}
                                        </label>
                                        <Checkbox
                                            {...register(f.key)}
                                            type="checkbox"
                                            name={f.key}
                                            id={f.key}
                                            defaultChecked={_.get(data, f.key, true)}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.richText) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__label">
                                        <label htmlFor="name">{f.label}</label>
                                    </div>
                                    <textarea name={f.key} id={f.key} style={{ display: 'none' }} defaultValue={_.get(data, f.key) || f?.defaultValue}></textarea>
                                    <div className="form-input__input">
                                        <TextEditor
                                            value={_.get(data, f.key) || ""}
                                            onChange={(v) => {
                                                console.log(v);
                                                const el = document.querySelector(`#${f.key}`)
                                                el.value = v
                                                setValue(f.key, v)
                                            }}
                                        ></TextEditor>
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.date) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__label">
                                        <label htmlFor={f.key}>{f.label}</label>
                                    </div>
                                    <div className="form-input__input">
                                        <TextField
                                            {...register(f.key)}
                                            name={f.key}
                                            id={f.key}
                                            defaultValue={
                                                _.get(data, f.key)
                                                    ? toNativeDate(new Date(_.get(data, f.key)))
                                                    : toNativeDate(new Date())
                                            }
                                            className="w-100"
                                            autoComplete="false"
                                            type="date"
                                            readOnly={f.readOnly}
                                            {...commonProps}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.datetime) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__label">
                                        <label htmlFor={f.key}>{f.label}</label>
                                    </div>
                                    <div className="form-input__input">
                                        <input
                                            {...register(f.key)}
                                            name={f.key}
                                            id={f.key}
                                            defaultValue={
                                                _.get(data, f.key, new Date())
                                            }
                                            className="w-100"
                                            autoComplete="false"
                                            type="datetime-local"
                                            readOnly={f.readOnly}
                                            {...commonProps}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.time) {
                            return (
                                <div className="form-input" key={f.key}>
                                    <div className="form-input__label">
                                        <label htmlFor={f.key}>{f.label}</label>
                                    </div>
                                    <div className="form-input__input">
                                        <input
                                            {...register(f.key)}
                                            name={f.key}
                                            id={f.key}
                                            defaultValue={
                                                _.get(data, f.key, new Date())
                                            }
                                            className="w-100"
                                            autoComplete="false"
                                            type="time"
                                            readOnly={f.readOnly}
                                            {...commonProps}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.file) {
                            return (
                                <div key={f.key} className="flex flex-col field-file m-b-32">
                                    <div className="preview">
                                        <label>{f.label}</label>
                                        {_.get(data, f.key) ? (
                                            <PreviewField file={_.get(data, f.key)} />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div>
                                        <input
                                            {...register(f.key)}
                                            type="file"
                                            id={f.key}
                                            name={f.key}
                                            onChange={(e) => {
                                                // Do validation && display preview of selected image
                                                console.log("onChange: file", { e, id: f.key, element: e.target });
                                                // const file = document.querySelector(`#${f.key}`).files[0];
                                                const file = e.target.files[0]
                                                const max = f.maxSize > 0 ? f.maxSize * 1000 * 1000 : 5 * 1000 * 1000; // 5MB
                                                console.log("File:", file);
                                                if (file && file.size > max) {
                                                    snack(
                                                        "Вы не можете загружать большие файлы! Пожалуйста используйте файл маленького размера"
                                                    );
                                                }

                                                const fileReader = new FileReader();
                                                const previewNode = e.target.parentNode.parentNode.querySelector('.preview')
                                                if (!previewNode.querySelector('img')) {
                                                    const img = document.createElement('img')
                                                    const imgWrapper = document.createElement('div')
                                                    imgWrapper.style = 'width: 100px; height: auto; margin-top: 1rem;'
                                                    imgWrapper.appendChild(img)
                                                    previewNode.appendChild(imgWrapper)
                                                }
                                                console.log("document.parentElement:", previewNode);

                                                const previewImage = previewNode.querySelector('img')

                                                fileReader.onload = event => {
                                                    previewImage.setAttribute('src', event.target.result);
                                                }
                                                fileReader.readAsDataURL(file);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (f.inputType === inputTypeEnum.chips) {
                            return <div className="m-b-32" key={f.key}>
                                <div className="m-b-16">{f.label}:</div>
                                <div className="flex gap-2">
                                    {
                                        f.options?.map((o, inx) => {
                                            return <span key={inx}>
                                                <label className="chip-label" htmlFor={o.id}>
                                                    {o.name}
                                                    <input
                                                        {...register(`${f.key}.${o.id}`)}
                                                        id={o.id}
                                                        type="checkbox"
                                                        name={`${f.key}.${o.id}`}
                                                        defaultChecked={data ? data[f.key]?.includes(o.id) : false}
                                                        style={{ display: 'none' }}
                                                    />
                                                </label>
                                            </span>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        if (f.inputType === inputTypeEnum.autocomplete) {
                            return <div key={f.key} className="form-control">
                                <Controller
                                    control={control}
                                    name={f.key}
                                    render={({ field: { ref, onChange, value, ...field } }) => {
                                        /**
                                         * Значение это ID сущности
                                         * options: это список объектов у которых ключь это ID name это название option 
                                         * isOptionEqualToValue - задает условие при котором отображается выбранный option
                                         * onInputChange - текстовое поля
                                        */

                                        let _value = value && f.options ? f.options.find(c => c.id === value) : f.value ? f.options.find(c => c.id === f.value) : null

                                        if (f.multiple && value) {
                                            _value = f.options.filter(o => value.includes(o.id))
                                        } else {
                                            _value = value
                                        }

                                        return (
                                            <Autocomplete
                                                {...field}
                                                value={_value}
                                                className="form-input"
                                                options={f?.options || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                multiple={Boolean(f.multiple)}
                                                onChange={(e, newValue) => {
                                                    console.log("newValue:", newValue);
                                                    if (f.multiple) {
                                                        onChange(newValue ? newValue.map(o => o.id) : null)
                                                    } else {
                                                        onChange(newValue ? newValue.id : null)

                                                        // const el = document.querySelector(`input[name="${f.key}"]`)
                                                        // el.value = newValue ? newValue.id : null
                                                    }
                                                }}
                                                onInputChange={(e) => {
                                                    // Когда меняется значение у текстого поля                                                
                                                    if (e && f.onChange) {
                                                        f.onChange(e.target.value)
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return <TextField {...params} {...commonProps} label={f.label} />;
                                                }}
                                            />
                                        );
                                    }}
                                ></Controller>
                            </div>
                        }
                        if (f.label) {
                            if (typeof f.label == 'function') {
                                return <div className="m-b-16" key={fieldIndex}>{f.label({ key: f.key })}</div>
                            }
                            return <h3 className="m-b-16" key={fieldIndex}>{f.label}</h3>
                        }
                        return <div key={fieldIndex}></div>;
                    })}
            </Box>
            <Button type="submit" variant="contained" disabled={isLoading}>
                {isLoading ? "Сохранение..." : "Сохранить"}
            </Button>
            {/* <pre>
                {JSON.stringify(watch(), null, 2)}
            </pre> */}
        </form>
    );
};
export default AppForm;
