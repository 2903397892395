import { Outlet } from "react-router-dom";
import { BookList } from "./BookList";
import BookForm from "./BookForm";

export const booksRoutes = [
  {
    path: "books",
    element: Outlet,
    children: [
      {
        path: "",
        element: BookList,
      },
      {
        path: "add",
        element: BookForm,
      },
      {
        path: "edit/:id",
        element: BookForm,
      },
    ],
  },
];
