import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useGetActiveDevices } from "hooks/useGetActiveDevices";

const ActiveDevices = () => {
    const { data: activeDevices, isLoading, error } = useGetActiveDevices()

    useEffect(() => {
        if (activeDevices?.length > 0) {
            const ctx = document.getElementById("activeDevices");
            const labels = activeDevices.map(x => x.date)
            const data = activeDevices.map(x => x.total)            

            new window.Chart(ctx, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: '',
                            data: data,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgb(75, 192, 192)',
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,                            
                        }
                    },
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Активные устройства по месяцам'
                        }
                    }
                },
            });
        }
    }, [activeDevices]);

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }
    if (error) {
        return <div>{error}</div>
    }
    return (
        <div className="flex-1 m-t-32">
            <h3 className="">Активные устройства по месяцам</h3>
            <div style={{ maxWidth: '100%' }}>
                <canvas id="activeDevices" style={{ width: '100% !important' }}></canvas>
            </div>
        </div>
    );
};

export default ActiveDevices