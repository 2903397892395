import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useGetAppVersions } from 'hooks/useGetAppVersions';
import AppVersionsSAService from 'services/superadmin/AppVersionsSAService';
import { Link } from 'react-router-dom';

const listConfig = [
    {
        key: 'id',
        label: 'ID',        
    },
    {
        key: 'versionIOS',
        label: 'Версия для iOS'
    },
    {
        key: "versionAndroid",
        label: "Версия для Android",
    },
    {
        key: "isActive",
        label: "Статус",
        formatter: (v) => v ? 'Активный' : 'Неактивный'
    },
    {
        key: "add-updates-button",
        label: "Добавить обновления",
        render: (v) => <Link style={{ textDecoration: 'none', color: 'blue' }} to={`/superadmin/app-versions/view/${v.id}/app-updates`}>Список обновления</Link>
    },
    TableColumns.createdDate,
]

export const AppVersionList = () => {
    const { data: appVersions, isLoading, refetch } = useGetAppVersions({});

    const onDelete = async (u) => {
        await AppVersionsSAService.delete(u.id);
        refetch();
    };

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Управление обновлениями приложений">
            <AppAdminTable
                columnConfig={listConfig}
                data={appVersions || []}
                onDelete={onDelete}
                loading={isLoading}
            />
        </ListPage>
    );
}
