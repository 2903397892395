import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import appAxios from "services/axios";

const DailyActivityForm = ({
  router: {
    navigate,
    params: { tourScheduleID, id },
  },
  data: { dailyActivity },
}) => {
  const fieldsWithData = [
    {
      key: "photo",
      label: "Основное фото",
      inputType: inputTypeEnum.file,
    },
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "dayNumber",
      label: "номер дня тура",
      required: true,
      inputType: inputTypeEnum.number,
    },
    {
      key: "description",
      label: "Описание",
      inputType: inputTypeEnum.richText,
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      const orgID = localStorage.getItem('orgID')
      if (dailyActivity) {
        await appAxios.put(`/admin/orgs/${orgID}/tour-schedules/${tourScheduleID}/daily-activities/${id}`, formValues)
        snack("Активити обновлен успешно!");
      } else {
        await appAxios.post(`/admin/orgs/${orgID}/tour-schedules/${tourScheduleID}/daily-activities`, formValues)
        snack("Активити создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={dailyActivity ? 'Редактирование дня' : 'Создание дня'}>
      <AppForm
        onSubmit={onSubmit}
        data={dailyActivity}
        fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(DailyActivityForm, (router) => {
    let requests = {}
    const { tourScheduleID, id } = router.params
    const orgID = localStorage.getItem('orgID')
    if (tourScheduleID && id && orgID) {
      requests['dailyActivity'] = () => appAxios.get(`/admin/orgs/${orgID}/tour-schedules/${tourScheduleID}/daily-activities/${id}`)
    }
    return useLoadAll(requests);
  })
);
