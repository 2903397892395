import { useQuery } from "@tanstack/react-query";
import AppVersionsSAService from "services/superadmin/AppVersionsSAService";

export const useGetAppVersions = (queryParams = {}) => {
    return useQuery({
        queryKey: ['appVersions', queryParams],
        queryFn: async () => {
            const { data } = await AppVersionsSAService.getAll(queryParams)
            return data
        },
        refetchOnWindowFocus: false,
    })
}