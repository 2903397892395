import { airportsRoutes } from "./Airports/airport-routes";
import { AllahNameRoutes } from "./AllahNames/allah-names-routes";
import { countriesRoutes } from "./Countries/countries-routes";
import { devicesRoutes } from "./Devices/devices-routes";
import { duaCategoryRoutes } from "./DuaCategories/duas-category-routes";
import { duasRoutes } from "./Duas/duas-routes";
import { eventsRoutes } from "./Events/events-routes";
import { organizationsRoutes } from "./Organizations/organization-routes";
import { remindersRoutes } from "./Reminders/reminders-routes";
import { statsSARoutes } from "./Stats/status-sa-routes";
import SuperAdminLayout from "./SuperAdminLayout";
import { supportsRoutes } from "./Support/support-routes";
import { usersRoutes } from "./Users/users-routes";
import { ramadanDiaryRoutes } from "./RamadanDiary/ramadan-diary-routes";
import { amalRoutes } from "./Amals/amals-routes";
import { appVersionsRoutes } from "./AppVersions/app-versions-routes";
import { booksRoutes } from "./Books/books-routes";
import { bookCategoryRoutes } from "./BookCategories/book-categories-routes";
export const superAdminRoutes = [
  {
    path: "superadmin",
    element: SuperAdminLayout,
    children: [
      ...organizationsRoutes,
      ...countriesRoutes,
      ...airportsRoutes,
      ...usersRoutes,
      ...duasRoutes,
      ...duaCategoryRoutes,
      ...supportsRoutes,
      ...remindersRoutes,
      ...statsSARoutes,
      ...eventsRoutes,
      ...devicesRoutes,
      ...AllahNameRoutes,
      ...ramadanDiaryRoutes,      
      ...amalRoutes,
      ...appVersionsRoutes,
      ...booksRoutes,
      ...bookCategoryRoutes,
    ],
  },
];
