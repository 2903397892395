import { Outlet } from "react-router-dom";
import { BookCategoryList } from "./BookCategoryList";
import BookCategoryForm from "./BookCategoryForm";

export const bookCategoryRoutes = [
  {
    path: "book-categories",
    element: Outlet,
    children: [
      {
        path: "",
        element: BookCategoryList,
      },
      {
        path: "add",
        element: BookCategoryForm,
      },
      {
        path: "edit/:id",
        element: BookCategoryForm,
      },
    ],
  },
];
