import withRouter from "hoc/withRouter";
import { Button, Card, CardActions, CardContent, CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import tourPackageCategoryAdminService from "services/admin/tourpackageCategoryService";
import { useGetTourPackages } from "hooks/useGetTourPackages";


const TourPackageList = ({
    data: {
        tourPackageCategories
    }
}) => {

    const { register, handleSubmit, getValues, watch } = useForm({
        defaultValues: {
            tourpackageCategoryID: '',
            status: true
        },
    });

    const { data: tourPackages, isLoading, error } = useGetTourPackages(watch());

    const onSubmit = (data) => {
        console.log(data);
    }

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (isLoading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (tourPackages.data.length === 0) {
            return <div>Турпакеты отсутсвуют. Чтобы добавить турпакет пожалуйста нажмите на кнопку "Добавить" сверху</div>
        }
        if (tourPackages.data.length > 0) {
            return tourPackages.data.map((t, inx) => {
                return <div className="m-b-16" key={inx}>
                    <Card className="p-16">
                        <CardContent>
                            <TourPackageCard tourPackage={t}></TourPackageCard>
                        </CardContent>
                        <CardActions className='justify-end'>
                            <Link to={`/admin/tour-packages/media/${t.id}`}>
                                <Button size="small">Медиа</Button>
                            </Link>
                            <Link to={`/admin/tour-packages/view/${t.id}`}>
                                <Button size="small">Детали</Button>
                            </Link>
                            <Link to={`/admin/tour-packages/edit/${t.id}`}>
                                <Button size="small">Редактировать</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </div>
            })
        }
    }
    return (
        <ListPage pageTitle="Турпакеты" pathToAdd="../add">
            <section className="filters flex flex-row">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row justify-between align-center">
                    <div className="flex flex-col w-min" >
                        <label>Категория</label>
                        <select {...register('tourpackageCategoryID')}>
                            <option value="">Все</option>
                            {tourPackageCategories.data.map((c, inx) => {
                                return <option value={c.id} key={inx}>{c.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="m-l-16 flex flex-col w-min" >
                        <label>Статус</label>
                        <select {...register('status')}>
                            <option value="" key={0}>Все</option>
                            <option value={true} key={1}>Активные</option>
                            <option value={false} key={2}>Неактивные</option>
                        </select>
                    </div>
                </form>
            </section>
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(
    withRemoteDataAndSpinner(TourPackageList, (router) => {
        let requests = {
            tourPackageCategories: () => tourPackageCategoryAdminService.getAll()
        }
        return useLoadAll(requests);
    })
);

