import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import tourPackageCategoryAdminService from "services/admin/tourpackageCategoryService";


const TourPackageCategoryForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { tourPackageCategory },
}) => {
  const fieldsWithData = [
    {
      key: "name",
      label: "Название категории",
      required: true,
      inputType: inputTypeEnum.text,
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (tourPackageCategory) {
        await tourPackageCategoryAdminService.put(id, formValues)
        snack("Категория обновлена успешно!");
      } else {
        await tourPackageCategoryAdminService.post(formValues)
        snack("Категория создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={tourPackageCategory ? 'Редактирование категории' : 'Создание категории'}>
      <AppForm onSubmit={onSubmit} data={tourPackageCategory} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(TourPackageCategoryForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['tourPackageCategory'] = () => tourPackageCategoryAdminService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
