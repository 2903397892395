import { useQuery } from "@tanstack/react-query";
import statsSAService from "services/superadmin/statsSAService";

export const useGetDeviceVersionNumbers = (platform) => {
    return useQuery({
        queryKey: ['deviceVersionNumbers', platform],
        queryFn: () => statsSAService.getDeviceVersionNumbers(platform),
        refetchOnWindowFocus: false,
        refetchOnMount: false,        
    })
}
