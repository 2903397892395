import { SuperAdminService } from "services/superAdminService";
import appAxios from "services/axios";
import { toQueryParams } from "helpers/url";

class AppVersionUpdatesSAService extends SuperAdminService<any> {
  constructor() {
    super("app-version-updates");
  }

  getAllUpdatesByVersionID = (queryParams: object) => {
    return appAxios.get(`/superadmin/app-version-updates?` + toQueryParams(queryParams));
  }
}
export default new AppVersionUpdatesSAService();
