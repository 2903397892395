import ActiveDevices from './ActiveDevices/ActiveDevices'
import NewUsers from './NewUsers/NewUsers'
import NewDevicesCount from './NewDevicesCount'
import DeviceVersionNumbers from './DeviceVersionNumbers/DeviceVersionNumbers'

const StatsSA = () => {
  return (
    <section>
      <div>
        <NewUsers></NewUsers>
      </div>
      <section>
        <ActiveDevices></ActiveDevices>
      </section>
      <section>
        <NewDevicesCount></NewDevicesCount>
      </section>
      <section className="m-t-32">
        <DeviceVersionNumbers platform="ios"></DeviceVersionNumbers>
      </section>
      <section className="m-t-32">
        <DeviceVersionNumbers platform="android"></DeviceVersionNumbers>
      </section>
    </section>
  )
}
export default StatsSA