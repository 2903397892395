import { Avatar, Button, Pagination, TextField } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { toDocumentFormatWithTime } from 'helpers/dateHelper';
import withRouter from 'hoc/withRouter';
import { useGetUsers } from 'hooks/useGetUsers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import usersSAService from 'services/superadmin/usersSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1,
    },
    {
        key: "photo",
        label: "Фото",
        render: (v) => v.photo && <Avatar src={v.photo} alt="user" />
    },
    {
        key: 'lastName',
        label: 'Фамилия'
    },
    {
        key: "firstName",
        label: "Имя",
    },
    {
        key: "phonePrefix",
        label: "Код страны",
    },
    {
        key: "phone",
        label: "Телефон",
    },
    {
        key: "IDCardNumber",
        label: "IDCard"
    },
    {
        key: "createdAt",
        label: "Дата создания",
        formatter: (v) => toDocumentFormatWithTime(v),
    },
    {
        key: 'updatedAt',
        label: 'Дата обновления',
        formatter: (v) => toDocumentFormatWithTime(v),
    },
    {
        key: "platform",
        label: "Платформа",
    },
    {
        key: 'lat',
        label: 'Координаты',
        render: (i) => [i.lat, i.long].filter(x => x).join('\n')
    },
    {
        key: "deviceToken",
        label: "Device Token",
    },
    {
        key: "email",
        label: "Почта",
    },
    {
        key: "id",
        label: "ID"
    },
]

const UserList = ({ router: { queryParams } }) => {
    const { register, handleSubmit, getValues, setValue, watch, reset, formState: { isValid } } = useForm({
        defaultValues: {
            phone: queryParams.get("phone") || "",
            page: queryParams.get("page") ? Number(queryParams.get("page")) : 1,
        }
    });

    console.log(`isValid:`, isValid);

    const values = watch();

    // Получаем данные если меняется значения в форме
    const { data, isLoading, refetch } = useGetUsers(values, {
        enabled: isValid
    });

    // useEffect(() => {
    //     // Используется для обновления состояние url
    //     urlReplaceState(null, getValues());
    // }, [getValues()]);


    useEffect(() => {
        if (values.phone) {
            setValue("page", 1);
            refetch();
        }
    }, [values.phone, setValue, refetch]);


    const onDelete = async (u) => {
        await usersSAService.delete(u.id);
        refetch();
    };

    const onPageChanged = (e, _page) => {
        setValue("page", Number(_page))
    }

    return (
        <ListPage pageTitle="Список пользователей">
            <div className="m-b-32">
                <form className="flex gap-2">
                    <TextField label="Номер телефона" {...register("phone", {
                        minLength: 10,
                    })}></TextField>
                    <Button type="button" onClick={() => reset({
                        page: 1,
                        phone: ""
                    })}>Сбросить</Button>
                </form>
            </div>
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data?.rows || []}
                onDelete={onDelete}
                loading={isLoading}
            />
            {data && <Pagination count={Math.ceil(data.data?.count / 50)} page={getValues("page")} onChange={onPageChanged} />}
        </ListPage >
    );
}

export default withRouter(UserList);