import { useQuery } from "@tanstack/react-query";
import statsSAService from "services/superadmin/statsSAService";

export const useGetActiveDevices = (queryParams = {}) => {
    return useQuery({
        queryKey: ['activeDevices', queryParams],
        queryFn: async () => {
            const { data } = await statsSAService.getActiveDevices(queryParams)
            return data
        },
        refetchOnWindowFocus: false,
    })
}