import { Outlet } from "react-router-dom";
import TourPackageCategoryForm from "./TourPackageCategoryForm";
import TourPackageCategoriesList from "./TourpackageCategoryList";

export const adminTourPackageCategoriesRoutes = [
  {
    path: "tourpackage-categories",
    element: Outlet,
    children: [
      {
        path: "",
        element: TourPackageCategoriesList,
      },
      {
        path: "add",
        element: TourPackageCategoryForm,
      },
      {
        path: "edit/:id",
        element: TourPackageCategoryForm,
      },
    ],
  },
];
