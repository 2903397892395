import { useQuery } from "@tanstack/react-query";
import booksSAService from "services/superadmin/booksSAService";

export const useGetBooks = (queryParams = {}) => {
    return useQuery({
        queryKey: ['getBooks', queryParams],
        queryFn: async () => {
            return booksSAService.getAll(queryParams)
        },
        select: (data) => data.data,
        refetchOnWindowFocus: false,
    })
}