import { useQuery } from "@tanstack/react-query";
import bookCategoriesSAService from "services/superadmin/bookCategoriesSAService";

export const useGetBookCategories = (queryParams = {}) => {
    return useQuery({
        queryKey: ['getBookCategories', queryParams],
        queryFn: async () => {
            return bookCategoriesSAService.getAll(queryParams)
        },
        select: (data) => data.data
    })
}