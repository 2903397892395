import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import AppVersionUpdatesSAService from "services/superadmin/AppVersionUpdatesSAService";

const AppVersionUpdateForm = ({
  router: {
    navigate,
    params: { id, updateID },
  },
  data: { appVersionUpdate },
}) => {

  const fieldsWithData = [
    {
      key: "title",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "link",
      label: "Ссылка",
      inputType: inputTypeEnum.text,
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      formValues.versionID = id;

      const formData = new FormData();
      Object.keys(formValues).forEach(key => {
        formData.append(key, formValues[key]);
      });

      if (appVersionUpdate) {
        await AppVersionUpdatesSAService.put(updateID, formData)
        snack("Обновление обновлено успешно!");
      } else {
        await AppVersionUpdatesSAService.post(formData)
        snack("Обновление создано успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={appVersionUpdate ? 'Редактирование обновления' : 'Создание обновления'}>
      <AppForm onSubmit={onSubmit} data={appVersionUpdate} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(AppVersionUpdateForm, (router) => {
    let requests = {}
    if (router.params.updateID) {
      requests['appVersionUpdate'] = () => AppVersionUpdatesSAService.get(router.params.updateID)
    }
    return useLoadAll(requests);
  })
);
