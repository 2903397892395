import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useGetBooks } from 'hooks/useGetBooks';
import booksSAService from 'services/superadmin/booksSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'name',
        label: 'Название'
    },
    {
        key: "category.name",
        label: "Категория",
    },
    {
        key: "language",
        label: "Язык",
    },
    {
        key: "status",
        label: "Статус",
        formatter: (v) => v ? 'Активный' : 'Неактивный'
    },
    TableColumns.createdDate,
]

export const BookList = () => {
    const { data, isLoading, refetch } = useGetBooks();

    const onDelete = async (u) => {
        await booksSAService.delete(u.id);
        refetch();
    };

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список книг">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={isLoading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
