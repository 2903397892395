import { Outlet } from "react-router-dom";
import DevicesList from "./DevicesList";

export const devicesRoutes = [
  {
    path: "devices",
    element: Outlet,
    children: [
      {
        path: "",
        element: DevicesList,
      },
    ],
  },
];
