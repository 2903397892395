import appAxios from "services/axios";
import { SuperAdminService } from "services/superAdminService";

class StatsSAService extends SuperAdminService<any> {
  constructor() {
    super("stats");
  }
  getNewUsers() {
    return appAxios.get(`/superadmin/stats/new-users`);
  }
  getActiveDevices() {
    return appAxios.get(`/scripts/total-devices`);
  }
  getNewDevicesCount() {
    return appAxios.get(`/superadmin/stats/new-devices-by-days`);
  }
  async getDeviceVersionNumbers(platform: string) {
    const { data } = await appAxios.get(`/superadmin/stats/device-version-numbers?platform=${platform}`);
    return data;
  }  
}
export default new StatsSAService();
