import { Outlet } from "react-router-dom";
import { AmalList } from "./AmalList";
import AmalForm from "./AmalForm";

export const amalRoutes = [
  {
    path: "amals",
    element: Outlet,
    children: [
      {
        path: "",
        element: AmalList,
      },
      {
        path: "add",
        element: AmalForm,
      },
      {
        path: "edit/:id",
        element: AmalForm,
      },
    ],
  },
];
