import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import AllahNamesSAService from "services/superadmin/AllahNamesSAService";

const AllahNameForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { allahName },
}) => {

  const fieldsWithData = [
    {
      key: "arabicName",
      label: "Название на арабском",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "transcript",
      label: "Транскрип",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "translation",
      label: "Перевод",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      required: true,
      inputType: inputTypeEnum.richText,
    },
    {
      key: "language",
      label: "Язык",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'kz',
          name: 'Казахский язык'
        },
        {
          id: 'ru',
          name: 'Русский язык'
        },
      ]
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (allahName) {
        await AllahNamesSAService.put(id, formValues)
        snack("Данное обновлен успешно!");
      } else {
        await AllahNamesSAService.post(formValues)
        snack("Данное успешно создан!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={allahName ? 'Редактирование записи' : 'Создание записи'}>
      <AppForm onSubmit={onSubmit} data={allahName} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(AllahNameForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['allahName'] = () => AllahNamesSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
