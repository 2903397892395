import { useQuery } from "@tanstack/react-query";
import AppVersionUpdatesSAService from "services/superadmin/AppVersionUpdatesSAService";

export const useGetAppVersionUpdates = (queryParams = {}) => {
    return useQuery({
        queryKey: ['appVersionUpdates', queryParams],
        queryFn: async () => {
            const { data } = await AppVersionUpdatesSAService.getAllUpdatesByVersionID(queryParams)
            return data
        },
        refetchOnWindowFocus: false,
    })
}