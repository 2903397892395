import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import eventsSAService from "services/superadmin/eventsSAService";

const EventForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { event },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "text",
      label: "Текст",
      required: true,
      inputType: inputTypeEnum.richText,
    },
    {
      key: "hijriDate",
      label: "Дата(лунный). Формат: MM-DD",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "language",
      label: "Язык",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'kz',
          name: 'Казахский язык'
        },
        {
          id: 'ru',
          name: 'Русский язык'
        },
      ]
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (event) {
        await eventsSAService.put(id, formValues)
        snack("Событие обновлено успешно!");
      } else {
        await eventsSAService.post(formValues)
        snack("Событие создано успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={event ? 'Редактирование собития' : 'Создание события'}>
      <AppForm onSubmit={onSubmit} data={event} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(EventForm, (router) => {
    let requests = {
    }
    if (router.params.id) {
      requests['event'] = () => eventsSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
