import { useQuery } from "@tanstack/react-query";
import statsSAService from "services/superadmin/statsSAService";

export const useGetNewDevicesCount = (queryParams = {}) => {
    return useQuery({
        queryKey: ['newDevicesCount', queryParams],
        queryFn: async () => {
            const { data } = await statsSAService.getNewDevicesCount(queryParams)
            return data
        },
        refetchOnWindowFocus: false,
    })
}