import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class HotelsAdminService extends BaseService<any> {
  constructor() {
    super(`hotels`);
  }
  uploadImages = async (id: number, formData: FormData) => {
    return appAxios.post(`${this.pathWithOrg()}/${id}/media`, formData);
  };
}

export default new HotelsAdminService();
