import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useGetNewDevicesCount } from "hooks/useGetNewDevicesCount";

const NewDevicesCount = () => {
    const { data: newDevicesCount, isLoading, error } = useGetNewDevicesCount()

    useEffect(() => {
        if (newDevicesCount) {
            const ctx = document.getElementById("newDevicesCount");
            const labels = newDevicesCount.map(x => x.date)
            const data = newDevicesCount.map(x => x.count)

            new window.Chart(ctx, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: '',
                            data: data,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgb(75, 192, 192)',
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Динамика новых устройств'
                        }
                    }
                },
            });
        }
    }, [newDevicesCount]);

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }
    if (error) {
        return <div>{error}</div>
    }
    return (
        <div className="flex-1 m-t-32">
            <h3 className="">Динамика новых устройств</h3>
            <div style={{ maxWidth: '100%' }}>
                <canvas id="newDevicesCount" style={{ width: '100% !important' }}></canvas>
            </div>
        </div>
    );
};

export default NewDevicesCount