import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import customersService from "services/admin/customersService";
import { snack } from "components/Snack/Snack";
import { objectToPlain } from "hajjtravel-utils";
import { Controller, useForm } from "react-hook-form";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import { genders } from "enums/genders";
import _ from "lodash";
import { FileField } from "components/FileField";
import { toFormData } from "helpers/formUtils";
import { countriesService } from "services/countries.service";

/**
 * Клиентская форма:   
 * Почему перевели на React Hook form:
 * - Название кнопки читабельный(Создать клиента и обновить клиента)
 * - Возможность добавление валидаций
 * - Возможность добавление гражданоство
 * - Использовать другой компонент для номера  телефона
 * - Задавать дефолтные значения
 */
const CustomerForm = ({ router: { navigate }, data: { customer, countries } }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted, touchedFields, },    
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      lastName: _.get(customer, 'lastName'),
      firstName: _.get(customer, 'firstName'),
      birthdate: _.get(customer, 'birthdate'),
      gender: _.get(customer, 'gender'),
      citizenshipID: _.get(customer, 'citizenshipID') ? _.get(customer, 'citizenshipID') : 'KAZ',
      photo: _.get(customer, 'photo', ''),
      user: {
        phone: _.get(customer, 'user.phone'),
        phonePrefix: _.get(customer, 'user.phonePrefix', 7) ? _.get(customer, 'user.phonePrefix') : 7,
        email: _.get(customer, 'user.email'),
      },
      IDCard: {
        iin: _.get(customer, 'IDCard.iin', ''),
        photoFront: _.get(customer, 'IDCard.photoFront', ''),
        photoBack: _.get(customer, 'IDCard.photoBack', ''),
      },
      passport: {
        dateOfIssue: _.get(customer, 'passport.dateOfIssue', ''),
        dateOfExpire: _.get(customer, 'passport.dateOfExpire', ''),
        number: _.get(customer, 'passport.number', ''),
        photo: _.get(customer, 'passport.photo', ''),
      },
      visa: {
        expiredAt: _.get(customer, 'visas[0].expiredAt', '')
      }
    },
  });

  const onSubmit = async (formValues) => {
    if (isSubmitting) {
      // Пред запрос обрабатывается
      return
    }
    try {
      console.log("formData1", formValues);
      // Перевести все одноуровеневый формат
      formValues = objectToPlain(formValues)
      console.log("formData2", formValues);
      const formData = toFormData(formValues)
      console.log("formData", formData.get('IDCard.photoFront'));

      if (customer) {
        await customersService.put(customer.id, formData)
        snack('Пользователь обновлен успешно!')
      } else {
        await customersService.post(formData)
        snack('Пользователь добавлен успешно!')
      }

      setTimeout(() => {
        navigate('/admin/customers')
      }, 500);
    } catch (ex) {
      console.error('Something wrong while adding user', ex)
    }
  };

  return (
    <CrudPage title={customer ? `Изменение клиента` : `Добавление нового клиента`}>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth {...register(`lastName`, { required: true })}
          label="Фамилия(как указан на паспорте)">
        </TextField>

        <TextField
          fullWidth {...register(`firstName`, { required: true })}
          label="Имя(как указан на паспорте)">
        </TextField>

        <Controller
          control={control}
          name="user.phone"
          render={({ field, fieldState, formState }) => {
            return <PhoneInput
              style={{ flex: 1.2 }}
              phoneNumber={field.value}
              countryCode={getValues('user.phonePrefix')}
              onChange={(params) => {
                if (params.countryCode) {
                  setValue('user.phonePrefix', params.countryCode)
                }
                if (params.phone !== undefined) {
                  setValue('user.phone', params.phone)
                }
              }}
            />
          }}
        ></Controller>

        <TextField fullWidth {...register(`user.email`)} label="Почта пользователя если имеется"></TextField>
        <TextField fullWidth {...register(`birthdate`, { required: true })} type="date" InputLabelProps={{ shrink: true }} label="Дата рождения"></TextField>
        <Controller control={control} name={`gender`} rules={{ required: true }} render={({ field }) => {
          return <FormControl style={{ width: 200 }}>
            <InputLabel>Пол</InputLabel>
            <Select label="Пол" {...field} value={field.value || ''}>
              {genders.map(g => {
                return <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        }}></Controller>
        <Controller control={control} name={`citizenshipID`} rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Гражданство</InputLabel>
            <Select label="Гражданство" {...field} value={field.value || ''}>
              {countries?.data?.map(c => {
                return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        }}></Controller>
        <FileField
          label="Фото клиента. Размер должен быть 4x6. Обязательно на белом фоне"
          defaultValue={getValues('photo')}
          {...register('photo')}
        ></FileField>

        <h3>Данные об удостоверение личности</h3>
        <TextField fullWidth {...register(`IDCard.iin`, { required: true, minLength: 12, maxLength: 12 })} label="ИИН пользователя"></TextField>
        <FileField
          label="Фото удостоверение личности(передняя часть)"
          defaultValue={getValues('IDCard.photoFront')}
          {...register('IDCard.photoFront')}
        ></FileField>

        <FileField
          label="Фото удостоверение личности(задняя часть)"
          defaultValue={getValues('IDCard.photoBack')}
          {...register('IDCard.photoBack')}
        ></FileField>

        <h3>Данные об паспорте</h3>
        <TextField fullWidth {...register(`passport.number`, { required: true, minLength: 9, maxLength: 9 })} label="Номер паспорта"></TextField>
        <TextField fullWidth {...register(`passport.dateOfIssue`, { required: true })} type="date" InputLabelProps={{ shrink: true }} label="Дата выдачи паспорта"></TextField>
        <TextField fullWidth {...register(`passport.dateOfExpire`, { required: true })} type="date" InputLabelProps={{ shrink: true }} label="Срок истечения паспорта"></TextField>
        <FileField
          label="Фото паспорта"
          defaultValue={getValues('passport.photo')}
          {...register('passport.photo')}
        ></FileField>

        <section>
          <TextField fullWidth {...register(`visa.expiredAt`)} type="date" InputLabelProps={{ shrink: true }} label="Срок истечения визы"></TextField>
        </section>

        <div className="m-t-32">
          <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
            {customer ? <span>Обновить клиента</span> : <span>Создать клиента</span>}
          </Button>
        </div>
        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre>         */}
      </form>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(CustomerForm, (router) => {
    const requsts = {
      countries: () => countriesService.getAll()
    }
    if (router.params.id) {
      requsts.customer = () => router.params.id ? customersService.get(router.params.id) : null
    }
    return useLoadAll(requsts);
  })
);
