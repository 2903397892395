import appAxios from "services/axios";
import { SuperAdminService } from "services/superAdminService";

class RemindersSAService extends SuperAdminService<any> {
  constructor() {
    super("reminders");
  }

  sendNotification(reminderID: string) {
    return appAxios.post(`/superadmin/reminders/${reminderID}/send`);
  }
}
export default new RemindersSAService();
