import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import ramadanDiarySAService from "services/superadmin/ramadanDiarySAService";
import amalsSAService from "services/superadmin/amalsSAService";

const RamadanDiaryForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { ramadanDiary, amals },
}) => {

  const fieldsWithData = [
    {
      key: "day",
      label: "День",
      required: true,
      inputType: inputTypeEnum.number,
    },
    {
      key: "text",
      label: "Текст",
      required: true,
      inputType: inputTypeEnum.richText,
    },
    {
      key: "language",
      label: "Язык",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'kz',
          name: 'Казахский язык'
        },
        {
          id: 'ru',
          name: 'Русский язык'
        },
      ]
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.checkbox
    },
    {
      key: "amals",
      label: "Амалдар",
      inputType: inputTypeEnum.autocomplete,
      options: amals.map(p => ({ id: p.id, name: p.name })),
      multiple: true
    }
  ]

  const onSubmit = async (formValues) => {
    try {
      if (ramadanDiary) {
        await ramadanDiarySAService.put(id, formValues)
        snack("Рамазан күнделігі обновлен успешно!");
      } else {
        await ramadanDiarySAService.post(formValues)
        snack("Рамазан күнделігі создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={ramadanDiary ? 'Редактирование рамазан күнделігі' : 'Создание рамазан күнделігі'}>
      <AppForm onSubmit={onSubmit} data={ramadanDiary} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(RamadanDiaryForm, (router) => {
    let requests = {
      amals: () => amalsSAService.getAll()
    }
    if (router.params.id) {
      requests['ramadanDiary'] = () => ramadanDiarySAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
