import { useQuery } from "@tanstack/react-query";
import usersSAService from "services/superadmin/usersSAService";

export const useGetUsers = (queryParams = {}) => {
    return useQuery({
        queryKey: ['getUsers', queryParams],
        queryFn: async () => {
            const { data } = await usersSAService.getAll(queryParams)
            return data
        },
        refetchOnWindowFocus: false,
    })
}