import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import remindersSAService from "services/superadmin/remindersSAService";

const ReminderForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { reminder },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Зоголовок",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "text",
      label: "Текст",
      required: true,
      inputType: inputTypeEnum.text,
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (reminder) {
        await remindersSAService.put(id, formValues)
        snack("Напоминание обновлено успешно!");
      } else {
        await remindersSAService.post(formValues)
        snack("Напоминание создано успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={reminder ? 'Создание напоминании' : 'Создание напоминании'}>
      <AppForm onSubmit={onSubmit} data={reminder} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(ReminderForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['reminder'] = () => remindersSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
