import { Outlet } from "react-router-dom";
import { RamadanDiaryList } from "./RamadanDiaryList";
import RamadanDiaryForm from "./RamadanDiaryForm";

export const ramadanDiaryRoutes = [
  {
    path: "ramadan-diary",
    element: Outlet,
    children: [
      {
        path: "",
        element: RamadanDiaryList,
      },
      {
        path: "add",
        element: RamadanDiaryForm,
      },
      {
        path: "edit/:id",
        element: RamadanDiaryForm,
      },
    ],
  },
];
