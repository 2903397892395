import { Outlet } from "react-router-dom";
import TourPackageList from "./TourPackageList";
import TourPackageForm from "./TourPackageForm";
import TourPackageView from "./TourPackageView";
import { adminHotelsRoutes } from "../Hotels/hotels-routes";
import { adminAttributesRoutes } from "../Attributes/attributes-routes";
import { adminAirlinesRoutes } from "../Airlines/airlines-routes";
import { adminFlightsRoutes } from "../Flights/flights-routes";
import { adminTourSchedulesRoutes } from "../TourSchedules/tour-schedule-routes";
import TourPackageMedia from "./TourPackageMedia/TourPackageMedia";
import { adminTourPackageCategoriesRoutes } from "../TourPackageCategories/tourpackage-categories-routes";

export const adminTourPackagesRoutes = [
  {
    path: "tour-packages",
    element: Outlet,
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: "list",
        element: TourPackageList,
      },
      {
        path: "add",
        element: TourPackageForm,
      },
      {
        path: "edit/:id",
        element: TourPackageForm,
      },
      {
        path: "view/:id",
        element: TourPackageView,
      },
      {
        path: "media/:id",
        element: TourPackageMedia,
      },
      ...adminHotelsRoutes,
      ...adminAttributesRoutes,
      ...adminAirlinesRoutes,
      ...adminFlightsRoutes,
      ...adminTourSchedulesRoutes,
      ...adminTourPackageCategoriesRoutes
    ],
  },
];
