import { Outlet } from "react-router-dom";
import StatsSA from "./StatsSA";

export const statsSARoutes = [
  {
    path: "stats",
    element: Outlet,
    children: [
      {
        path: "",
        element: StatsSA,
      },
    ],
  },
];
