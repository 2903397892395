import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import ramadanDiarySAService from 'services/superadmin/ramadanDiarySAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'day',
        label: 'День'
    },
    {
        key: "startDate",
        label: "Начало",        
    },
    {
        key: "language",
        label: "Язык",
    },
    {
        key: "isActive",
        label: "Статус",
        formatter: (v) => v ? 'Активный' : 'Неактивный'
    },
    TableColumns.createdDate,
]

export const RamadanDiaryList = () => {
    const [data, loadData, loading] = useRemoteResource(ramadanDiarySAService.getAll, "");

    const onDelete = async (u) => {
        await ramadanDiarySAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Рамазан күнделігі">
            <AppAdminTable
                columnConfig={listConfig}
                data={data || []}
                onDelete={onDelete}
                loading={loading}
            />            
        </ListPage>
    );
}
