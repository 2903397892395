import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class TourPackagesService extends BaseService<any> {
  constructor() {
    super(`tour-packages`);
  }

  uploadImages = async (id: number, formData: FormData) => {
    return appAxios.post(`${this.pathWithOrg()}/${id}/media`, formData);
  };
}

export default new TourPackagesService();
