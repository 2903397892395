import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import locationsService from "services/admin/locationsService";
import { toFormData } from "helpers/formUtils";

const LocationForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { location },
}) => {
  const fieldsWithData = [
    {
      key: "photo",
      label: "Основное фото авиакомпаний",
      inputType: inputTypeEnum.file,
    },
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "lat",
      label: "Ширина",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "long",
      label: "Долгота",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "isActive",
      label: "Статус",
      inputType: inputTypeEnum.checkbox
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      const formData = toFormData(formValues);

      if (location) {
        await locationsService.put(id, formData)
        snack("Локация обновлена успешно!");
      } else {
        await locationsService.post(formData)
        snack("Локация создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={location ? 'Редактирование локации' : 'Создание локации'}>
      <AppForm onSubmit={onSubmit} data={location} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(LocationForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['location'] = () => locationsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
