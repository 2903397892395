import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useGetAppVersionUpdates } from 'hooks/useGetAppVersionUpdates';
import { useParams } from 'react-router-dom';
import AppVersionUpdatesSAService from 'services/superadmin/AppVersionUpdatesSAService';

const listConfig = [
    {
        key: 'id',
        label: 'ID'        
    },
    {
        key: 'title',
        label: 'Название'
    },
    {
        key: "link",
        label: "Ссылка",
    },
    TableColumns.createdDate,
]

export const AppVersionUpdatesList = () => {
    const params = useParams();
    const { data: versionUpdates, isLoading, refetch } = useGetAppVersionUpdates({ versionID: params.id });

    console.log(`versionUpdates: `, versionUpdates);


    const onDelete = async (u) => {
        await AppVersionUpdatesSAService.delete(u.id);
        refetch();
    };

    if (isLoading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Управление обновлениями приложений">
            <AppAdminTable
                columnConfig={listConfig}
                data={versionUpdates?.data || []}
                onDelete={onDelete}
                loading={isLoading}
            />
        </ListPage>
    );
}
