import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import duaCategoriesSAService from "services/superadmin/duaCategoriesSAService";

const DuaCategoryForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { duaCategory },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Название константы",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.checkbox
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (duaCategory) {
        await duaCategoriesSAService.put(id, formValues)
        snack("Категория обновлена успешно!");
      } else {
        await duaCategoriesSAService.post(formValues)
        snack("Категория создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={duaCategory ? 'Редактирование категории' : 'Создание категории'}>
      <AppForm onSubmit={onSubmit} data={duaCategory} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(DuaCategoryForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['duaCategory'] = () => duaCategoriesSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
