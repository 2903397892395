import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import amalsSAService from 'services/superadmin/amalsSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'name',
        label: 'Название'
    },
    {
        key: 'reward',
        label: 'Награда'
    },
    {
        key: "isActive",
        label: "Статус",
        formatter: (v) => !!v ? 'Активный' : 'Неактивный'
    },
    TableColumns.createdDate,
]

export const AmalList = () => {
    const [data, loadData, loading] = useRemoteResource(amalsSAService.getAll, "");

    const onDelete = async (u) => {
        await amalsSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Амалдар">
            <AppAdminTable
                columnConfig={listConfig}
                data={data || []}
                onDelete={onDelete}
                loading={loading}
            />
        </ListPage>
    );
}
