import { useQuery } from "@tanstack/react-query";
import tourPackagesService from "services/admin/tour-packages.service";

export const useGetTourPackages = (queryParams = {}) => {
    return useQuery({
        queryKey: ['tourPackages', queryParams],
        queryFn: async () => {
            const { data } = await tourPackagesService.getAll(queryParams)
            return data
        }
    })
}