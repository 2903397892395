import { Button, CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import remindersSAService from 'services/superadmin/remindersSAService';


async function sendNotification(n) {
    try {
        if (!n) {
            alert('Bad request')
            return
        }
        let isConfirmed = window.confirm('Вы точно хотете отправить сообщение всем пользователям?')
        if (isConfirmed) {
            await remindersSAService.sendNotification(n.id)
            alert('Сообщение отправлено всем пользователям')
        }
    } catch (error) {
        console.log(`sendNotification:`, error);
        alert('Вышла ошибка во время отправки сообщения все пользователям. Посмотрите логи: ' + error?.message)
    }
}

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'name',
        label: 'Зоголовок'
    },
    {
        key: 'text',
        label: 'Текст'
    },
    TableColumns.createdDate,
    {
        key: "send",
        label: '',
        render: (i) => {
            return <Button onClick={() => sendNotification(i)}>Отправить всем</Button>
        }
    },
]

export const ReminderList = () => {
    const [data, loadData, loading] = useRemoteResource(remindersSAService.getAll, "");

    const onDelete = async (u) => {
        await remindersSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список напоминаний">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
