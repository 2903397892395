import { CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { getLast12Months } from "helpers/dateHelper";
import React, { useEffect, useState } from "react";
import statsSAService from "services/superadmin/statsSAService";

const NewUsers = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (data) {
            const ctx = document.getElementById("newMonthly");
            const labels = getLast12Months()
            new window.Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: labels.map(l => {
                            const value = data.find(x => x.month === l)
                            return value ? value.sum : 0
                        }),
                        backgroundColor: new Array(12).fill('rgba(75, 192, 192, 0.2)'),
                        borderColor: new Array(12).fill('rgb(75, 192, 192)'),
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                },
            });
        }
    }, [data]);

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        try {
            setLoading(true)
            const result = await statsSAService.getNewUsers()
            setData(result.data)
        } catch (ex) {
            snack(ex?.message)
            setError(ex?.message)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    if (error) {
        return <div>{error}</div>
    }

    return (
        <div className="flex-1">
            <h3 className="">Новые пользователи по месяцам</h3>
            <div style={{ maxWidth: '100%' }}>
                <canvas id="newMonthly" style={{ width: '100% !important' }}></canvas>
            </div>
        </div>
    );
};

export default NewUsers