import { CircularProgress, Pagination } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { toDocumentFormatWithTime } from 'helpers/dateHelper';
import { urlReplaceState } from 'helpers/url';
import withRouter from 'hoc/withRouter';
import { useRemoteResource } from 'hooks/useRemoteResource';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import devicesSAService from 'services/superadmin/devicesSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1,
    },
    {
        key: 'deviceID',
        label: 'ID устройства'
    },
    {
        key: "platform",
        label: "Платформа",
    },
    {
        key: "version",
        label: "Версия",
    },
    {
        key: "language",
        label: "Язык",
    },
    {
        key: "userID",
        label: "Пользователь",
        render: (d) => _.get(d, 'userID') ? <Link target='_blank' to={`/superadmin/users/edit/${d.userID}`}>{_.get(d, 'user.phone')}</Link> : null
    },
    {
        key: "createdAt",
        label: "Дата создания",
        formatter: (v) => toDocumentFormatWithTime(v),
    },
    {
        key: 'updatedAt',
        label: 'Дата обновления',
        formatter: (v) => toDocumentFormatWithTime(v),
    },
    {
        key: "deviceToken",
        label: "Token",
    },
]

const DeviceList = ({ router: { queryParams } }) => {
    const [query, setQuery] = useState({
        page: queryParams.get("page") || 1,
    });
    const [data, loadData, loading] = useRemoteResource(devicesSAService.getAll, query);

    useEffect(() => {
        // Используется для обновления состояние url
        urlReplaceState(null, query);
    }, [query]);


    const onDelete = async (u) => {
        await devicesSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    const onPageChanged = (e, _page) => {
        setQuery({
            ...query,
            page: _page
        })
    }

    return (
        <ListPage pageTitle="Список устройств">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.rows || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination count={Math.ceil(data?.count / 50)} page={query.page} onChange={onPageChanged} />}
        </ListPage>
    );
}

export default withRouter(DeviceList);