import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import eventsSAService from 'services/superadmin/eventsSAService';

const listConfig = [
    {
        key: "order",
        label: "#",
        render: (v, i) => i + 1
    },
    {
        key: 'name',
        label: 'Название'
    },
    {
        key: "language",
        label: "Язык",
    },
    {
        key: "hijriDate",
        label: "Дата(лунный)",
    },
    {
        key: "date",
        label: "Дата(григор.)",
    },
    TableColumns.createdDate,
]

export const EventList = () => {
    const [data, loadData, loading] = useRemoteResource(eventsSAService.getAll, "");

    const onDelete = async (u) => {
        await eventsSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список событий">
            <AppAdminTable
                columnConfig={listConfig}
                data={data || []}
                onDelete={onDelete}
                loading={loading}
            />
        </ListPage>
    );
}
