import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import booksSAService from "services/superadmin/booksSAService";
import bookCategoriesSAService from "services/superadmin/bookCategoriesSAService";
import { toFormData } from "helpers/formUtils";
import { useState } from "react";
const BookForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { book, bookCategories },
}) => {
  const fieldsWithData = [
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "photo",
      label: "Фото",
      inputType: inputTypeEnum.file,
    },
    {
      key: "path",
      label: "Книга в формате PDF",
      inputType: inputTypeEnum.file,
      maxSize: 50,
    },
    {
      key: "description",
      label: "Описание",
      required: true,
      inputType: inputTypeEnum.richText,
    },
    {
      key: "language",
      label: "Язык",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'kz',
          name: 'Казахский язык'
        },
        {
          id: 'ru',
          name: 'Русский язык'
        },
      ]
    },
    {
      key: "categoryID",
      label: "Категория",
      inputType: inputTypeEnum.select,
      options: bookCategories.data.map((c) => ({
        id: c.id,
        name: c.name,
      })
      )
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.checkbox
    },
  ]

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formValues) => {
    try {
      setIsLoading(true);
      const formData = toFormData(formValues);

      if (book) {
        await booksSAService.put(id, formData)
        snack("Книга обновлена успешно!");
      } else {
        await booksSAService.post(formData)
        snack("Книга создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CrudPage title={book ? 'Редактирование книги' : 'Создание книги'}>
      <AppForm onSubmit={onSubmit} data={book} fields={fieldsWithData} isLoading={isLoading}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(BookForm, (router) => {
    let requests = {
      bookCategories: () => bookCategoriesSAService.getAll()
    }
    if (router.params.id) {
      requests['book'] = () => booksSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
