import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import amalsSAService from "services/superadmin/amalsSAService";

const AmalForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { amal },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "Название",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "reward",
      label: "Награда",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "isActive",
      label: "Активный",
      inputType: inputTypeEnum.checkbox
    },
  ]

  const onSubmit = async (formValues) => {
    try {
      if (amal) {
        await amalsSAService.put(id, formValues)
        snack("Амал обновлен успешно!");
      } else {
        await amalsSAService.post(formValues)
        snack("Амал создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={amal ? 'Редактирование амала' : 'Создание амала'}>
      <AppForm onSubmit={onSubmit} data={amal} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(AmalForm, (router) => {
    let requests = {}

    if (router.params.id) {
      requests['amal'] = () => amalsSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
